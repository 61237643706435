<template>
  <Parent>
    <template slot="content">
      <validation-observer ref="observer">
        <v-form ref="post_form" @submit.prevent="submit">
          <v-row>
            <v-col cols="12" md="9">
              <v-card>
                <v-card-title>
                  <v-row align="center">
                    <v-col cols="auto">
                      {{ $t('page_notifications_create_title') }}
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <div class="text-right mb-2">
                          <validation-provider
                            v-slot="{ errors }"
                            name="post_date"
                            rules="required"
                          >
                            <v-dialog
                              ref="post_date_dialog"
                              v-model="post_date_model"
                              :return-value.sync="post_date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  text
                                  class="hint-text"
                                >
                                  {{ $t('posted_date') }}:
                                  {{ post_date ? post_date : currentDate() }}
                                  <v-icon size="16" right>$edit</v-icon>
                                </v-btn>
                                <v-text-field
                                  dense
                                  hidden
                                  class="no-border"
                                  v-model="post_date"
                                  :error-messages="errors"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                </v-text-field>
                              </template>
                              <v-date-picker v-model="post_date" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="post_date_model = false"
                                >
                                  {{ $t('cancel') }}
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs.post_date_dialog.save(post_date)
                                  "
                                >
                                  {{ $t('ok') }}
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </validation-provider>
                        </div>
                        <validation-provider
                          v-slot="{ errors }"
                          name="title"
                          :rules="rules.title"
                        >
                          <v-text-field
                            solo
                            hide-details
                            :placeholder="$t('post_title')"
                            dense
                            class="post-title"
                            v-model="title"
                            :error-messages="errors"
                            @input="enteredTextTitle = title.length"
                            maxlength="100"
                          >
                          </v-text-field>
                          <div class="error--text m-0" v-if="errors[0]">
                            {{ errors[0] }}
                          </div>
                        </validation-provider>
                        <template>
                          <div class="hint-text text-md-right">
                            {{ $t('word_count', { count: enteredTextTitle }) }}
                          </div>
                        </template>
                      </v-col>

                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="description"
                        >
                          <PostEditor
                            :data.sync="description"
                            :error-messages="errors"
                          ></PostEditor>
                          <div class="error--text m-0" v-if="errors[0]">
                            {{ errors[0] }}
                          </div>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="3">
              <v-row>
                <v-col cols="12" order="last" order-md="first">
                  <v-card class="text-center">
                    <v-card-text class="px-0 py-0">
                      <v-btn
                        tile
                        block
                        text
                        depressed
                        min-height="50"
                        class="custom-button"
                        @click="submit('draft')"
                      >
                        {{ $t('save_draft') }}
                      </v-btn>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-text class="px-0 py-0">
                      <v-btn
                        tile
                        block
                        text
                        depressed
                        min-height="50"
                        class="custom-button"
                      >
                        {{ $t('preview') }}
                      </v-btn>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-text>
                      <v-row>
                        <v-col cols="12" lg="6">
                          <!-- <v-btn class="custom-button delete" text
                          >Delete Post</v-btn
                        > -->
                        </v-col>
                        <v-col cols="12" lg="6">
                          <v-btn
                            type="submit"
                            class="custom-button"
                            min-width="100"
                            color="primary"
                            depressed
                            >
                            {{ $t('publish') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card class="target-group-card">
                    <v-card-title>
                      {{ $t('target_group') }}
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-card-text>
                      <validation-provider
                        v-slot="{ errors }"
                        name="selectedGroup"
                        :rules="rules.selectedGroup"
                      >
                        <v-checkbox
                          :ripple="false"
                          hide-details
                          on-icon="$customCheckboxOn"
                          off-icon="$customCheckboxOff"
                          class="mt-0"
                          dense
                          v-for="group in groups"
                          :key="group.id"
                          :value="group.id"
                          v-model="selectedGroup"
                          :error-messages="errors"
                        >
                          <template v-slot:label>
                            <div class="checkbox-label">{{ group.name }}</div>
                          </template>
                        </v-checkbox>
                      </validation-provider>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card>
                    <v-card-text class="px-0 py-0">
                      <v-container class="py-0">
                        <v-row align="center">
                          <v-col
                            cols="3"
                            md="12"
                            lg="3"
                            class="text-center label-type"
                          >
                            {{ $t('type') }}
                          </v-col>

                          <v-divider
                            :vertical="this.$vuetify.breakpoint.name !== 'md'"
                          ></v-divider>

                          <v-col cols="9" md="12" lg="9" class="px-2 py-0">
                            <validation-provider
                              v-slot="{ errors }"
                              name="selectedType"
                              :rules="rules.selectedType"
                            >
                              <v-select
                                solo
                                hide-details
                                flat
                                class="rounded-0"
                                color="primary"
                                item-text="name"
                                item-value="id"
                                :label="$t('select_type')"
                                :items="notificationTypes"
                                v-model="selectedType"
                                :error-messages="errors"
                              >
                              </v-select>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card class="category-card">
                    <v-card-title>
                      {{ $t('category') }}
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-card-text>
                      <validation-provider
                        v-slot="{ errors }"
                        name="selectedCategory"
                        :rules="rules.selectedCategory"
                      >
                        <v-radio-group
                          class="mt-0"
                          v-model="selectedCategory"
                          :error-messages="errors"
                        >
                          <v-radio
                            v-for="category in categories"
                            :key="category.id"
                            :label="category.name"
                            :value="category.id"
                            :ripple="false"
                          ></v-radio>
                        </v-radio-group>
                      </validation-provider>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card class="category-card">
                    <v-card-title>
                      {{ $tc('tag', 2) }}
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-card-text>
                      <validation-provider
                        v-slot="{ errors }"
                        name="selectedTags"
                        :rules="rules.selectedTags"
                      >
                        <v-autocomplete
                          v-model="selectedTags"
                          :error-messages="errors"
                          :items="tags"
                          item-text="name"
                          item-value="name"
                          :menu-props="{ maxHeight: '400' }"
                          :label="$t('select_tag')"
                          multiple
                          dense
                          chips
                          deletable-chips
                          small-chips
                        ></v-autocomplete>
                      </validation-provider>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </template>
  </Parent>
</template>

<script>
import Parent from '@/views/admin/Notification/index'
import PostEditor from '@/components/admin/partials/PostEditor/PostEditor'

export default {
  name: 'index',
  components: { PostEditor, Parent },
  props: {
    mode: {
      type: String,
      default: 'create',
      required: false
    }
  },
  created() {
    this.getCategoryFromApi()
  },

  computed: {
    categories() {
      return this.$store.getters.allCategories
    },
    tags() {
      return this.$store.getters.allTags
    },

    notificationTypes() {
      return this.$store.getters.allNotificationTypes
    },
    groups() {
      return this.$store.getters.groups
    }
  },

  data() {
    return {
      post_date_model: false,
      formStatus: false,
      post_date: null,
      is_draft: false,
      selectedTags: null,
      selectedGroup: [],
      selectedCategory: '',
      selectedType: '',
      title: null,
      group_id: null,
      rules: {
        title: 'required',
        description: 'required',
        selectedCategory: 'required',
        selectedType: 'required',
        selectedGroup: 'required'
      },
      enteredTextTitle: 0,

      description: '',
      loading: false,
      errors: ''
    }
  },
  methods: {
    currentDate() {
      const current = new Date()
      let year = current.getFullYear()
      let month = (current.getMonth() + 1).toString().padStart(2, '0')
      let day = current
        .getDate()
        .toString()
        .padStart(2, '0')
      const date = `${year}-${month}-${day}`
      return date
    },
    async getCategoryFromApi() {
      this.loading = true
      this.$store.dispatch('GET_ALL_CATEGORIES')
      this.$store.dispatch('GET_ALL_TAGS')
      this.$store.dispatch('GET_ALL_NOTIFICATION_TYPES')
      this.$store.dispatch('GROUP_GET_ALL')
      this.selectedType = this.notificationTypes.length
        ? this.notificationTypes[0]?.id
        : null // Set first index as default
      this.loading = false
    },
    async submit(statusType) {
      this.formStatus = true
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.formStatus = false
          return
        }

        let data = {}

        data.title = this.title
        data.description = this.description
        data.category_id = this.selectedCategory
        data.type_id = this.selectedType
        data.post_date = this.post_date ? this.post_date : this.currentDate()
        data.tags = this.selectedTags
        data.group = this.selectedGroup
        data.is_draft = 0
        if (statusType == 'draft') {
          data.is_draft = 1
        }
        this.$store
          .dispatch('ADD_NOTIFICATION', data)
          .then(result => {
            if (result.status === 200) {
              this.$refs.observer.reset()
              this.$router.push({ name: 'NotificationList' })
            }
            this.formStatus = false
          })
          .catch(error => {
            this.$refs.observer.setErrors(error.data.error.errors)
          })
      })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
