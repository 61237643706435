<template>
  <ckeditor :editor="editor" v-model="_text" :config="editorConfig"></ckeditor>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import UploadAdapter from '@/plugins/uploadadapter'

export default {
  name: 'PostEditor',
  props: {
    data: {
      type: String,
      default: '',
      required: true
    }
  },
  computed: {
    _text: {
      get: function() {
        return this.data
      },
      set: function(newValue) {
        return this.$emit('update:data', newValue)
      }
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [this.uploader]
      }
    }
  },
  methods: {
    uploader(editor) {
      editor.plugins.get('FileRepository').createUploadAdapter = loader => {
        return new UploadAdapter(loader)
      }
    }
  }
}
</script>

<style lang="scss" src="./PostEditor.scss" scoped></style>
