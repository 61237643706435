var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Parent',[_c('template',{slot:"content"},[_c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"post_form",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t('page_notifications_create_title'))+" ")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-right mb-2"},[_c('validation-provider',{attrs:{"name":"post_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-dialog',{ref:"post_date_dialog",attrs:{"return-value":_vm.post_date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.post_date=$event},"update:return-value":function($event){_vm.post_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"hint-text",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('posted_date'))+": "+_vm._s(_vm.post_date ? _vm.post_date : _vm.currentDate())+" "),_c('v-icon',{attrs:{"size":"16","right":""}},[_vm._v("$edit")])],1),_c('v-text-field',_vm._g(_vm._b({staticClass:"no-border",attrs:{"dense":"","hidden":"","error-messages":errors},model:{value:(_vm.post_date),callback:function ($$v) {_vm.post_date=$$v},expression:"post_date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.post_date_model),callback:function ($$v) {_vm.post_date_model=$$v},expression:"post_date_model"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.post_date),callback:function ($$v) {_vm.post_date=$$v},expression:"post_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.post_date_model = false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.post_date_dialog.save(_vm.post_date)}}},[_vm._v(" "+_vm._s(_vm.$t('ok'))+" ")])],1)],1)]}}])})],1),_c('validation-provider',{attrs:{"name":"title","rules":_vm.rules.title},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"post-title",attrs:{"solo":"","hide-details":"","placeholder":_vm.$t('post_title'),"dense":"","error-messages":errors,"maxlength":"100"},on:{"input":function($event){_vm.enteredTextTitle = _vm.title.length}},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),(errors[0])?_c('div',{staticClass:"error--text m-0"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])}),[_c('div',{staticClass:"hint-text text-md-right"},[_vm._v(" "+_vm._s(_vm.$t('word_count', { count: _vm.enteredTextTitle }))+" ")])]],2),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('PostEditor',{attrs:{"data":_vm.description,"error-messages":errors},on:{"update:data":function($event){_vm.description=$event}}}),(errors[0])?_c('div',{staticClass:"error--text m-0"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","order":"last","order-md":"first"}},[_c('v-card',{staticClass:"text-center"},[_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-btn',{staticClass:"custom-button",attrs:{"tile":"","block":"","text":"","depressed":"","min-height":"50"},on:{"click":function($event){return _vm.submit('draft')}}},[_vm._v(" "+_vm._s(_vm.$t('save_draft'))+" ")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-btn',{staticClass:"custom-button",attrs:{"tile":"","block":"","text":"","depressed":"","min-height":"50"}},[_vm._v(" "+_vm._s(_vm.$t('preview'))+" ")])],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}}),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-btn',{staticClass:"custom-button",attrs:{"type":"submit","min-width":"100","color":"primary","depressed":""}},[_vm._v(" "+_vm._s(_vm.$t('publish'))+" ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"target-group-card"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('target_group'))+" ")]),_c('v-divider'),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"selectedGroup","rules":_vm.rules.selectedGroup},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _vm._l((_vm.groups),function(group){return _c('v-checkbox',{key:group.id,staticClass:"mt-0",attrs:{"ripple":false,"hide-details":"","on-icon":"$customCheckboxOn","off-icon":"$customCheckboxOff","dense":"","value":group.id,"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"checkbox-label"},[_vm._v(_vm._s(group.name))])]},proxy:true}],null,true),model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}})})}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-container',{staticClass:"py-0"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-center label-type",attrs:{"cols":"3","md":"12","lg":"3"}},[_vm._v(" "+_vm._s(_vm.$t('type'))+" ")]),_c('v-divider',{attrs:{"vertical":this.$vuetify.breakpoint.name !== 'md'}}),_c('v-col',{staticClass:"px-2 py-0",attrs:{"cols":"9","md":"12","lg":"9"}},[_c('validation-provider',{attrs:{"name":"selectedType","rules":_vm.rules.selectedType},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-0",attrs:{"solo":"","hide-details":"","flat":"","color":"primary","item-text":"name","item-value":"id","label":_vm.$t('select_type'),"items":_vm.notificationTypes,"error-messages":errors},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})]}}])})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"category-card"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('category'))+" ")]),_c('v-divider'),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"selectedCategory","rules":_vm.rules.selectedCategory},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mt-0",attrs:{"error-messages":errors},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}},_vm._l((_vm.categories),function(category){return _c('v-radio',{key:category.id,attrs:{"label":category.name,"value":category.id,"ripple":false}})}),1)]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"category-card"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$tc('tag', 2))+" ")]),_c('v-divider'),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"selectedTags","rules":_vm.rules.selectedTags},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"items":_vm.tags,"item-text":"name","item-value":"name","menu-props":{ maxHeight: '400' },"label":_vm.$t('select_tag'),"multiple":"","dense":"","chips":"","deletable-chips":"","small-chips":""},model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}})]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }